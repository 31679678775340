import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { useParams, Link } from 'react-router-dom'

export default function Layout() {
    const { uid } = useParams()
    const [galleryFiles, setGalleryFiles] = useState([])
    useEffect(() => {
        axios.get(`https://api.kiwisanta.photo/files/${uid}`).then(response => {
            setGalleryFiles(response.data)
        })
    }, [])

    const URLS = galleryFiles.map(gallery => {
        return (
            gallery.urls?.map(url => {
                if (url.includes('-thumb') && !url.includes('._')) {
                    const fullres = url.replace('-thumb', '')
                    return (
                      
                            <div key={url} style={{}}>
                                <div style={{ width: '30%' }}>
                                    <a href={`https://api.kiwisanta.photo/photo?url=${fullres}`} key={url}>
                                        <img src={`https://api.kiwisanta.photo/photo?url=${url}`} />
                                    </a>
                                </div>
                            </div>

                        // <a target="_blank" href={`https://localhost:8000/photo?url=${url}`} key={url}>Photo</a>
                    )
                }

            })

        )

    }

    )
    // const URLS = urls?.map(url => {
    //     if (url.includes('-thumb')) {
    //         const fullres = url.replace('-thumb', '')
    //         return (
    //             <div style={{}}>
    //                 <div style={{ width: '30%' }}>
    //                     <a href={`https://api.kiwisanta.photo/photo?url=${fullres}`} key={url}>
    //                         <img src={`https://api.kiwisanta.photo/photo?url=${url}`} />
    //                     </a>
    //                 </div>
    //             </div>
    //             // <a target="_blank" href={`https://localhost:8000/photo?url=${url}`} key={url}>Photo</a>
    //         )
    //     }
    // })
    return (
        <div style={{ width: '100vw', display: 'flex', flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'space-around' }}>

            {URLS}
        </div>
    )
}
