import logo from './logo.svg';
import { BrowserRouter, Routes, Route, Link } from "react-router-dom";

import './App.css';
import Layout from './Layout';

function App() {
  
  return (
    <BrowserRouter>
      <div className="App">
        <Routes>
          <Route path='/gallery/:uid/*' element={<Layout />} />
        </Routes>
      </div>
    </BrowserRouter>
  );
}

export default App;
